<template>
<div class="submitted mr-8 ml-4">
  <template v-if="submitted !== null">
    <v-btn v-if="!submitted" text outlined :x-small="small"
           @click="mark(true)">
      Mark submitted
    </v-btn>
    <v-btn v-else outlined color="warning" :x-small="small"
           @click="mark(false)">
      Submitted
    </v-btn>
  </template>
</div>
</template>

<script>
import * as api from '../../api'

export default {
  name: 'Submitted',
  props: {
    document: String,
    detail: String,
    targetUserId: {
      type: Number,
      default: null
    },
    value: {
      type: Boolean,
      default: null
    },
    small: Boolean
  },
  data() {
    return {
      internalSubmitted: null
    }
  },
  watch: {
    document() {
      this.fetch()
    },
    detail() {
      this.fetch()
    }
  },
  computed: {
    submitted: {
      get() {
        return this.internalSubmitted === null ? this.value : this.internalSubmitted
      },
      set(v) {
        this.internalSubmitted = v
      }
    }
  },
  methods: {
    async fetch() {
      const { submitted } = await api.get('/submitted', this.createPayload())
      this.submitted = submitted
    },
    createPayload() {
      const { document, detail, targetUserId } = this
      const payload = { document, detail }
      if (targetUserId) {
        payload.target_user_id = targetUserId
      }
      return payload
    },
    mark(on) {
      const method = on ? api.post : api.del
      this.submitted = on
      return method('/submit', this.createPayload())
    }
  },
  created() {
    if (this.value === null) {
      this.fetch()
    }
  }
}
</script>

<style scoped>
div.submitted {
  display: inline;
}
</style>
