<template>
<v-simple-table dense>
  <thead>
  <tr>
    <th class="number">Date</th>
    <th>Job</th>
    <th class="number">Job #</th>
    <th class="number">T1</th>
    <th class="number">T1.5</th>
    <th class="number">T2</th>
    <th class="number">T3</th>
    <th class="number">Own car</th>
    <th class="number">Mileage</th>
    <th class="number">Extra</th>
    <th class="approved">Approved</th>
  </tr>
  </thead>
  <tbody>
  <tr v-for="day in sortedDays" :key="day.uid"
      :class="{ unapproved: !approved[day.uid] && !day.additional_day }">
    <td>
      <router-link  class="text-decoration-none"
                    :to="{ name: 'user-calendar', params: { userSlug: slugify(day.user_name) },
                     query: { date: extractDate(day.start) } }">
        {{ day.date }}
        <v-icon v-if="day.flagged" color="warning">mdi-flag</v-icon>
      </router-link>
    </td>
    <td>
      {{ day.job_name }}
      <v-chip v-if="day.type === 'workshop'" color="secondary" x-small class="ml-2">W</v-chip>
    </td>
    <td>{{ day.job_number }}</td>
    <td>{{ day.ot_1 }}</td>
    <td>{{ day.ot_1p5 }}</td>
    <td>{{ day.ot_2 }}</td>
    <td>{{ day.ot_3 }}</td>
    <td>{{ day.car_with_kit }}</td>
    <td>{{ day.mileage }}</td>
    <td>{{ day.extra_pay > 0 ? `£${day.extra_pay}` : '' }}</td>
    <td>
      <v-simple-checkbox v-if="!day.additional_day" :value="approved[day.uid]"
                         @input="approvedChanged(day, $event)" :ripple="false">
      </v-simple-checkbox>
    </td>
  </tr>
  </tbody>
</v-simple-table>
</template>

<script>
import { PayrollMixin } from '../../mixins/PayrollMixin'

export default {
  name: 'OvertimeTable',
  mixins: [PayrollMixin],
  created() {
    this.syncApproved()
  }
}
</script>

<style scoped>
th.approved {
  width: 10px;
}
th.number {
  width: 8%;
}
tr.unapproved {
  opacity: 0.5;
}
</style>
