<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-badge overlap color="blue lighten-1" class="ml-2 mr-2"
               :content="userDownloads" :value="userDownloads > 0">
        <v-icon v-bind="attrs" v-on="on">mdi-history</v-icon>
      </v-badge>
    </template>
    <v-card>
      <v-app-bar flat>
        <span>{{ makeTitle(document) }} downloads for {{ readableDate }}</span>
      </v-app-bar>

      <v-card-text ref="report" class="mt-5">
        <p v-for="download in downloads" :key="download.id"
           :class="{ 'current-user': currentUser(download) }">
          {{ format(download) }}
        </p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as api from '../../api'
import dayjs from 'dayjs'
import { makeTitle } from '../../util'
import { mapState } from 'vuex'

export default {
  name: 'DownloadHistory',
  props: {
    document: String,
    date: String,
    detail: String,
    userId: {
      type: Number,
      default: null
    },
    targetUserId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      downloads: [],
      lastFetch: 0
    }
  },
  watch: {
    document() {
      this.fetch()
    },
    date() {
      this.fetch()
    },
    detail() {
      this.fetch()
    },
    userId() {
      if (this.userId) this.fetch()
    },
    targetUserId() {
      if (this.targetUserId) this.fetch()
    }
  },
  computed: {
    ...mapState(['user']),
    readableDate() {
      if (this.document === 'payroll') {
        return dayjs(this.date).format('MMMM YYYY')
      } else {
        return dayjs(this.date).format('DD MMMM YYYY')
      }
    },
    userDownloads() {
      return this.downloads.filter(d => d.name === this.user.name).length
    }
  },
  methods: {
    async fetch() {
      const { document, detail, userId, targetUserId } = this
      if (document && detail) {
        const now = new Date()
        const last = this.lastFetch
        this.lastFetch = now
        if (now - last > 100) {
          const payload = { document, detail }
          if (userId) {
            payload.user_id = userId
          }
          if (targetUserId) {
            payload.target_user_id = targetUserId
          }
          this.downloads = await api.get('/downloads', payload)
        }
      }
    },
    format(download) {
      const { name, document, timestamp } = download
      const date = dayjs(timestamp).format('dddd DD MMMM YYYY')
      let output = ''
      if (this.document === 'payroll') {
        output += `${makeTitle(document)} on `
      }
      output += `${date} by ${name}`
      return output
    },
    currentUser(download) {
      return this.$store.state.user.name === download.name
    }
  },
  async created() {
  },
  mounted() {
    this.fetch()
  }
}
</script>

<style scoped lang="scss">
p {
  margin-bottom: 0.5rem;

  &.current-user {
    font-weight: bold;
 }
}
</style>
