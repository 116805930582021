<template>
<div>
  <v-simple-table dense>
    <thead>
    <tr>
      <th class="number">Date</th>
      <th class="text-left">Job</th>
      <th class="number">Job #</th>
      <th class="number">Miles</th>
      <th class="number">Total</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="day in days" :key="day.uid">
      <td> {{ day.date }}</td>
      <td>{{ day.job_name }}</td>
      <td class="text-center">{{ day.job_number }}</td>
      <td class="text-right">{{ day.mileage }}</td>
      <td class="text-right">£{{ (day.mileage_fee).toFixed(2) }}</td>
    </tr>
    <tr class="font-weight-bold">
      <td colspan="3">Total</td>
      <td class="text-right">{{ totalMiles }}</td>
      <td class="text-right">£{{ totalCost.toFixed(2) }}</td>
    </tr>
    </tbody>
  </v-simple-table>
</div>
</template>

<script>
const printStyle = `
<style>
table, tr, th, td {
  border: 1px solid #999;
  padding: 5px;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th.number {
  width: 70px;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.font-weight-bold {
  font-weight: bold;
}
</style>
`

export default {
  name: 'MileageTable',
  printStyle,
  props: {
    user: String,
    days: Array
  },
  computed: {
    totalMiles() {
      return this.days.reduce((t, day) => t + day.mileage, 0)
    },
    totalCost() {
      return this.days.reduce((t, day) => t + day.mileage_fee, 0)
    }
  }
}
</script>

<style scoped>
th.number {
  width: 12%;
}
</style>
