import * as api from '../api'

export const PayrollMixin = {
  props: ['days'],
  data() {
    return {
      approved: {}
    }
  },
  watch: {
    days() {
      this.syncApproved()
    }
  },
  computed: {
    sortedDays() {
      const days = this.days.map(e => e)
      return days.sort((a, b) => a.date.localeCompare(b.date))
    }
  },
  methods: {
    syncApproved() {
      const approved = {}
      for (const day of this.days) {
        approved[day.uid] = day.approved
      }
      this.approved = approved
    },
    async approvedChanged(day, approved) {
      this.$set(this.approved, day.uid, approved)
      this.$forceUpdate() // not sure why we need this
      await api.put('/shift/approved', { shift_type: day.type, shift_id: day.id, approved })
    }
  }
}
