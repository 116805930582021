<template>
<v-dialog v-model="dialog" max-width="700px">
  <template v-slot:activator="{ on, attrs }">
    <v-btn color="primary" v-bind="attrs" v-on="on">
      Mileage
    </v-btn>
  </template>
  <v-card>
    <v-app-bar flat>
      <span>Monthly Mileage Report</span>
      <v-spacer></v-spacer>
      <span>{{ mileageMonth }}</span>
    </v-app-bar>

    <v-card-text ref="report" class="mt-5">
      <div v-for="[user, days] in report" :key="user" class="user-mileage-report">
        <header class="d-none">
          <h2>Monthly Mileage Report</h2>
          <div class="text-right">
            <b>Payment: {{ paymentMonth }}</b><br>
            <span>Incl. mileage from {{ mileageMonth }}</span>
            <p><i>All mileage paid at £0.45 per mile</i></p>
          </div>
        </header>
        <h3 class="black--text ml-2">{{ user }}</h3>
        <mileage-table :days="days"></mileage-table>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="print">
        Print
      </v-btn>
      <v-btn text @click="dialog = false">
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import * as api from '../../api'
import dayjs from 'dayjs'
import MileageTable from './MileageTable'

const printStyle = `
<style>
div.user-mileage-report {
  font-family: Arial, sans-serif;
  page-break-after: always;
  width: 800px;
}
header {
  display: block;
}
</style>
`

export default {
  name: 'MileageReport',
  components: { MileageTable },
  props: {
    month: String
  },
  data() {
    return {
      dialog: false,
      report: {}
    }
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        this.report = await api.get('/staff-ot', { month: this.month, only_approved: true, only_mileage: true })
      }
    }
  },
  computed: {
    mileageMonth() {
      return dayjs(this.month).format('MMMM YYYY')
    },
    paymentMonth() {
      return dayjs(this.month).add(1, 'month').format('MMMM YYYY')
    }
  },
  methods: {
    print() {
      const w = window.open()
      w.document.write(this.$refs.report.innerHTML)
      w.document.write(MileageTable.printStyle)
      w.document.write(printStyle)
      w.document.close()
      w.focus()
      w.print()
      w.addEventListener('afterprint', (e) => {
        w.close()
        this.dialog = false
      })

      api.post('/download/mark', { document: 'mileage', detail: this.month })
    }
  }
}
</script>

<style scoped>
div.user-mileage-report {
  margin-bottom: 2rem;
}
</style>
